import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { safeCall } from '../../helpers/React'

import styles from './styles'


let Link = null

let LinkParamsTransformer = (props) => props

const useStyles = createUseStyles(styles)

const A = (props) => {
  const classes = useStyles(props)
  const {
    children,
    className,
    onClick,
    preventDefaultOnClick,
    onMouseEnter,
    disabled,
    href,
    route,
    target,
    title,
    rel,
    enabled,
    scroll,
    routeParams,
      asAnchor,
    ...otherProps
  } = props

  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (window) {
      const uris = window.location.pathname.split('#')

      setUrl(uris[0])
    }
  }, [setUrl])

  const isAnchor = useMemo(() => {
    if (asAnchor) {
      return true
    }

    if (href) {
      const tableParams = href.split('#')

      if (tableParams[1] && (!tableParams[0] || tableParams[0] === '' || tableParams[0] === url)) {
        return true
      }
    }
    return false
  }, [asAnchor, href, url])

  // handlers

  const handleOnclick = useCallback((e) => {
    // don't scroll if it's an anchor
    if (isAnchor && preventDefaultOnClick) {
      e.preventDefault()
    }
    safeCall(onClick, props)
  }, [isAnchor, onClick, preventDefaultOnClick, props])

  // renderers

  const renderDefault = useMemo(
    () => (
      <div
        className={className}
        onClick={!disabled ? handleOnclick : undefined}
        onMouseEnter={!disabled ? onMouseEnter : undefined}
      >
        {children}
      </div>
    ),
    [className, disabled, handleOnclick, onMouseEnter, children]
  )

  const params = LinkParamsTransformer(props)
  const renderAsLink = (target && target !== '_self') || (href && href.match(/^(h|\/\/)/)) || !Link || !params ? (
    <a
      {...otherProps}
      title={title}
      href={href}
      aria-label={title}
      target={target}
      className={cx(classes.link, className)}
      onClick={handleOnclick}
      onMouseEnter={onMouseEnter}
      rel={rel}
    >
      {children}
    </a>
  ) : (
    <Link
      {...params}
      scroll={scroll}
    >
      <a
        {...otherProps}
        title={title}
        aria-label={title}
        className={cx(classes.link, className)}
        onClick={handleOnclick}
        onMouseEnter={onMouseEnter}
        role="presentation"
      >
        {children}
      </a>
    </Link>
  )

  // return

  return <>{(href || route) && !disabled ? renderAsLink : renderDefault}</>
}

A.setLinkComponent = (linkComponent) => {
  Link = linkComponent
}

A.setLinkParamsTransformer = (linkParamsTransformer) => {
  LinkParamsTransformer = linkParamsTransformer
}

A.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  preventDefaultOnClick: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  href: PropTypes.string,
  route: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  routeParams: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  rel: PropTypes.string,
  scroll: PropTypes.bool,
  asAnchor: PropTypes.bool,
}

A.defaultProps = {
  children: null,
  className: null,
  onClick: null,
  preventDefaultOnClick: true,
  onMouseEnter: null,
  href: null,
  route: null,
  routeParams: null,
  target: null,
  title: null,
  disabled: false,
  enabled: false,
  scroll: true,
  rel: 'nofollow noreferrer',
  asAnchor: false,
}

export default withMemo()(A)
