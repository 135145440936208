import nextRoutes from 'next-routes'

import A from '../components/A'

import r from './definitions'


const routes = r
const router = nextRoutes()

Object.values(routes).map(({ component, path, name }) => router.add({
  page: component,
  name,
  pattern: path,
}))

router.Router.getRouteUrl = (route, params = {}) => {
  if (!route || !route.name) {
    return ''
  }
  let routeUrl

  try {
    routeUrl = router.findAndGetUrls(route.name, params)?.urls?.as
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('findAndGetUrls Error', e)
    return ''
  }

  Object.keys(params).forEach((key) => {
    routeUrl = routeUrl.replace(new RegExp(`:${key}\\??`, 'gm'), params[key])
    routeUrl = decodeURIComponent(routeUrl)
    routeUrl = routeUrl.replace('//', '/')
  })
  return routeUrl
}

export default routes
export const Link = router.Link
export const Router = router.Router

A.setLinkComponent(router.Link)
A.setLinkParamsTransformer((props) => {
  const route = props.route || props.href

  if (!route || typeof route !== 'string' || route.indexOf('http') === 0) {
    return false
  }

  if (route === '/') {
    return {
      route: routes.home.name,
    }
  }

  const params = props.routeParams || undefined

  const registeredRoute = routes[route]

  if (!registeredRoute) {
    return {
      route: route.indexOf('/') === 0 ? route : `/${route}`,
      params,
    }
  }

  const matches = registeredRoute.path.match(/:([a-z0-9?]+)/gi)

  if (matches) {
    const missingKeys = Array.from(matches)
      ?.map((m) => m.substr(1))
      .filter((m) => m && m.indexOf('?') === -1 && (!params || !params[m]))

    if (missingKeys.length) {
      // eslint-disable-next-line no-console
      console.warn(`[A] param "${missingKeys[0]}" is not defined`, { route })
      return false
    }
  }

  return {
    route,
    params,
  }
})
